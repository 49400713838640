/*.bg1 {
    background-color: #e8ecef;
    
    .bg1 .divider.divider-border , .divider.divider-border {
        color: #d0d4d8;
    }
    .divider:after, .divider.divider-center:before, .divider.divider-center.divider-short:before {
         border-top: 1px solid #d0d4d8;
    }
    
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
    background-color: #032a6f;
}

.box-white {
    border: 1px solid #dfdfdf;
    background: #fff;
    border-radius: 4px;
    padding: 25px 35px;
    font-size: 16px;
    color: #707070;
}

#kontakt-form input, #kontakt-form textarea {
    margin: 8px 0;
    border-width: 1px;
    border-radius: 4px!important;
}
#kontakt-form input:focus, #kontakt-form textarea:focus {
    border-color: #d26f18;
}
#kontakt-form textarea {
    height: 162px;
}
.btn-kontakt {
    width: 100%;
}
.btn-primary {
    color: #ffffff;
    background-color: @theme-color;
    border-color: @theme-color;
}
#primary-menu.style-2 > div > ul > li#fb-ico {
    a {
        padding-top: 19px;
        padding-bottom: 10px;
        
        
        i {
            font-size: 22px;
        }
    }
}*/


a, button {
    transition: all 0.1s ease-in-out 0s;
}

.bg1 {
    background-color: #e8ecef;
    
    .bg1 .divider.divider-border , .divider.divider-border {
        color: #d0d4d8;
    }
    .divider:after, .divider.divider-center:before, .divider.divider-center.divider-short:before {
         border-top: 1px solid #d0d4d8;
    }
    
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
    background-color: #032a6f;
}

.box-white {
    border: 1px solid #dfdfdf;
    background: #fff;
    border-radius: 4px;
    padding: 25px 35px;
    font-size: 16px;
    color: #707070;
}

#kontakt-form input, #kontakt-form textarea {
    margin: 0;
    border-width: 1px;
    border-radius: 4px!important;
}
#kontakt-form input {
    margin-bottom: 8px;
}
#kontakt-form input:focus, #kontakt-form textarea:focus {
    border-color: #d26f18;
}
#kontakt-form textarea {
    height: 177px;
}
.btn-kontakt {
    width: 100%;
}
.btn-primary {
    color: #ffffff;
    background-color: @theme-color;
    border-color: @theme-color;
}
#copyrights {
    font-size: 16px;
}
#copyrights a {
    color: #fff;
}
#copyrights a:hover {
   // color: #e76e45;
    color: #d92417;
}
#piksel img{
    opacity: 0.5;
    transition: all 0.1s ease-in-out 0s;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
}
#piksel img:hover{
    opacity: 1;
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    filter: grayscale(0%);
}
.btn-primary:hover {
    color: #ffffff;
    background-color: #032a6f;
    border-color: #032a6f;
}
.button-red {
    background-color: #d92417;
}

.button-reveal.button-red:hover {
        background-color: #032a6f !important
}
.justify {
    text-align: justify;
}

.quiz-red {
    color: #d92417;
}
.quiz-green {
    font-weight:bold;
    color: #3c763d;
}

.quiz .heading-block:after {
    border-top:0;
}
/*
.radio label  {
    line-height:20px;
    
    input {
        line-height:20px;
        height:20px;
    }
}*/

.quiz-odp-odstep {
    margin-top:0px;
}


@media (max-width: 991px) {
    #kontakt-prawa {
        padding-left: 0;
    }
    #kontakt-lewa {
        padding-right: 0;
        margin-bottom: 15px;
    }
    .quiz-odp-odstep {
        margin-top:6px;
    }
}
